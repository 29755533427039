import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Axios for making HTTP requests

const UserForm = ({ onSubmit }) => {
  const [student, setStudent] = useState('Jane Doe');
  const [email, setEmail] = useState('Jane.doe@gmail.com');
  const [age, setAge] = useState(12);
  const [topics, setTopics] = useState([]); // State to store fetched topics

  // Fetch topics when the component mounts
  useEffect(() => {
    if (email) {
      axios
      .get(`https://api.best-ai-chatbots.com/api/topics`)

        // .get(`http://localhost:5000/api/topics`)
        .then((response) => {
          setTopics(response.data.topics); // Update topics state
        })
        .catch((error) => {
          console.error('Error fetching topics:', error);
        });
    }
  }, [email]);

  const handleSubmit = (subject) => {
    onSubmit({ student, email, age, subject });
  };

  return (
    <div className="user-form-container">
      <form className="user-form">
        <h2 className="form-title">Welcome to Our Class</h2>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" value={student} onChange={(e) => setStudent(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Age:</label>
          <input type="number" value={age} onChange={(e) => setAge(e.target.value)} required />
        </div>

        {/* Topics Section */}
        <div className="form-group topics-section">
          <label>All Topics:</label>
          <div className="topics-container">
            {topics.length > 0 ? (
              topics.map((topic) => (
                <button
                  key={topic.id}
                  type="button"
                  className="topic-button"
                  onClick={() => handleSubmit(topic.topic_name)}
                >
                  {topic.topic_name}
                </button>
              ))
            ) : (
              <p>Loading topics...</p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
