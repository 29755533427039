import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';  // Using Bootstrap components for a professional look
import 'bootstrap/dist/css/bootstrap.min.css';  // Ensure Bootstrap is installed

const TopicsManager = ({ userData }) => {
    const [topics, setTopics] = useState([]);
    const [newTopic, setNewTopic] = useState({ topic_name: "", llm: "", prompt: "", email: userData.email });
    const [editingTopic, setEditingTopic] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);  // Modal for delete confirmation
    const [topicToDelete, setTopicToDelete] = useState(null);
    const email = userData.email;

    // List of available LLM models for the dropdown
    const llmOptions = ["gpt4o", "gpt4o-mini", "claude", "LLama"];

    // Fetch topics from the backend API
    useEffect(() => {
        if (email) {
            fetchTopics(email);
        }
    }, [email]);

    const fetchTopics = async (email) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.best-ai-chatbots.com/api/topics?email=${email}`);  // Fetch from local backend
            setTopics(response.data.topics);
            toast.success("Topics loaded successfully");
        } catch (error) {
            setError("Failed to load topics. Please check your network or try again later.");
            toast.error("Failed to load topics.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTopic(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const postData = editingTopic ? editingTopic : newTopic;
    
        try {
            if (editingTopic) {
                // Update the existing topic
                await axios.post(
                    'https://api.best-ai-chatbots.com/api/topics',
                    { ...postData },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                toast.success("Topic updated successfully!");
                setEditingTopic(null);
            } else {
                // Add a new topic
                await axios.post(
                    'https://api.best-ai-chatbots.com/api/topics',
                    { ...postData },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                toast.success("Topic added successfully!");
                setNewTopic({ topic_name: "", llm: "", prompt: "", email: userData.email});  // Reset form
            }
            fetchTopics(email); // Refresh the topic list after adding/updating
        } catch (error) {
            console.error("Error:", error.response ? error.response.data : error);
            toast.error("Failed to save topic. Please check your input and try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (topic) => {
        setEditingTopic(topic);
    };

    const confirmDelete = (topicName) => {
        setTopicToDelete(topicName);
        setShowModal(true);  // Show modal for confirmation
    };

    const handleDelete = async () => {
        setLoading(true);
        setShowModal(false);  // Hide the modal after confirmation
        try {
            await axios.delete(`https://api.best-ai-chatbots.com/api/topics/${topicToDelete}`, { params: { email } });
            toast.success("Topic deleted successfully!");
            fetchTopics(email); // Refresh the list
        } catch (error) {
            console.error("Error:", error.response ? error.response.data : error);
            toast.error("Failed to delete topic. Please try again.");
        } finally {
            setLoading(false);
            setTopicToDelete(null);
        }
    };

    if (loading) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div className="topics-manager container">
            <ToastContainer />
            <h2 className="my-4">Manage Topics</h2>

            {error && <div className="alert alert-danger">{error}</div>}

            {/* Add / Update Topic Form */}
            <form onSubmit={handleSubmit} className="mb-4">
                <div className="form-group">
                    <label>Topic Name:</label>
                    <input
                        type="text"
                        name="topic_name"
                        className="form-control"
                        value={editingTopic ? editingTopic.topic_name : newTopic.topic_name}
                        onChange={handleInputChange}
                        disabled={!!editingTopic} // Disable changing the name when editing
                        required
                    />
                </div>
                <div className="form-group">
                    <label>LLM Model:</label>
                    <select
                        name="llm"
                        className="form-control"
                        value={editingTopic ? editingTopic.llm : newTopic.llm}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select LLM Model</option>
                        {llmOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Prompt:</label>
                    <input
                        type="text"
                        name="prompt"
                        className="form-control"
                        value={editingTopic ? editingTopic.prompt : newTopic.prompt}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <Button type="submit" variant="primary" disabled={loading} className="mr-2">
                    {editingTopic ? "Update Topic" : "Add Topic"}
                </Button>
                {editingTopic && (
                    <Button variant="secondary" onClick={() => setEditingTopic(null)}>
                        Cancel Edit
                    </Button>
                )}
            </form>

            {/* Topic List */}
            <h3>Existing Topics</h3>
            {topics.length === 0 ? (
                <p>No topics available.</p>
            ) : (
                <ul className="list-group">
                    {topics.map((topic) => (
                        <li key={topic.id} className="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                                <strong>{topic.topic_name}</strong> - {topic.llm} - {topic.prompt}
                            </div>
                            <div>
                                <Button variant="info" onClick={() => handleEdit(topic)} className="mr-2">
                                    Edit
                                </Button>
                                <Button variant="danger" onClick={() => confirmDelete(topic.topic_name)}>
                                    Delete
                                </Button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            {/* Delete Confirmation Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this topic?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TopicsManager;
