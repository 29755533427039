import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

const Speech = forwardRef(({ onSpeak, userData }, ref) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const audioRef = useRef(null); // Reference for the audio element

  const generateSpeech = async (text) => {
    console.log("in the speech function");
    try {
      console.log("in the speech function try");

      const response = await fetch(
          // "http://localhost:5000/generate_speech",

        "https://api.best-ai-chatbots.com/generate_speech",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ text, email: userData?.email }),
        }
      );
      console.log("in the speech function try", response);

      if (!response.ok) throw new Error("Failed to generate speech");

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      console.log("audio url", audioUrl);
      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
      }
    } catch (error) {
      console.error("Error generating speech:", error);
    }
  };

  const speak = async (text) => {
    if (isSpeaking) {
      // Stop speaking by stopping the audio playback
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = ""; // Clear the audio source
      }
      setIsSpeaking(false);
      onSpeak(false);
      return;
    }

    if (text) {
      setIsSpeaking(true);
      onSpeak(true);
      await generateSpeech(text);

      // Reset speaking state when audio ends
      audioRef.current.onended = () => {
        setIsSpeaking(false);
        onSpeak(false);
      };
    } else {
      console.log("No text to speak.");
    }
  };

  useImperativeHandle(ref, () => ({
    speak,
  }));

  return (
    <div className="speak-button-container">
      {/* <button onClick={() => speak(isSpeaking ? '' : 'Ok, let\'s see...')}>
        {isSpeaking ? "Stop Speaking" : "Start Learning"}
      </button> */}
      <audio ref={audioRef} style={{ display: "none" }} />
    </div>
  );
});

export default Speech;
